<template>
  <div class="modal" :class="showModalFlag ? 'is-active' : ''">
    <div class="modal-background"></div>
    <div v-if="showModalFlag" class="modal-card">
      <header
        class="modal-card-head"
        style="flex-direction: row; align-content: flex-start"
      >
        <p class="modal-card-title">
          {{ period.name }} (<span v-if="period">{{ period.show }}</span
          >)
        </p>
        <button class="delete" aria-label="close" v-on:click="cancelModal()">
          >
        </button>
      </header>
      <section class="modal-card-body" style="min-height: 80px">
        <div class="list">
          <ul class="mylist">
            <div class="list-item">
              <li v-for="student in students" :key="student.PupilIdent">
                <span
                  >{{ student.PupilFirstName }} {{ student.PupilLastName }}
                </span>

                <span class="is-pulled-right">
                  <input
                    type="checkbox"
                    v-if="[46, 0].indexOf(student.attendancePeriod.id) !== -1"
                    v-model="checks[student.PupilIdent]"
                  />
                  <span v-else>{{ student.attendancePeriod.mark }} </span>
                </span>
              </li>
            </div>
          </ul>
        </div>
      </section>
      <footer
        class="modal-card-foot is-center"
        style="
          text-align: center;
          align-content: flex-start;
          flex-direction: column;
        "
      >
        <p class="is-size-6 mt-2 has-text-centered">CHECKED are...</p>
        <div style="margin: auto">
          <button class="button is-success" v-on:click="okModal('Present')">
            Present
          </button>
          <button class="button is-danger" v-on:click="okModal('Absent')">
            Absent
          </button>
        </div>
        <p class="is-size-7 mt-2 has-text-centered">
          remaining will treated as the opposite
        </p>
      </footer>
    </div>
  </div>
</template>

<script>
import timetable from "helpers/timetable";

export default {
  name: "modal-student-register-group",

  data: function () {
    return {
      showModalFlag: false,
      okPressed: false,
      students: null,
      currentrm: null,
      regPeriod: null,
      regMarks: null,
      period: null,
      checks: {},
      pid: null,
      gid: null,
    };
  },

  computed: {},
  methods: {
    showModal: function (group, regDetails, regMarks, date, period, gid, pid) {
      console.log({ group, regDetails, regMarks, date, period, gid, pid });
      this.okPressed = false;
      this.showModalFlag = true;

      this.currentrm = regDetails;
      this.date = date;
      this.regMarks = regMarks;
      this.period = period;
      this.pid = pid;
      this.gid = gid;

      let t = {};

      Object.keys(regMarks).forEach((x) => {
        t[regMarks[x].ident] = regMarks[x].code;
      });

      let a = [];

      group[0].forEach((el) => {
        let y = group[1][el];

        y.attendancePeriod = {
          id: regDetails[el]?.RegisterMarkID,
          mark: t[regDetails[el]?.RegisterMarkID] ?? undefined,
        };

        a.push(y);
      });

      this.students = a;
    },
    okModal: async function (type) {
      this.okPressed = true;
      this.showModalFlag = false;

      let data = {
        students: Object.keys(this.checks),
        mode: type,
        pid: this.pid,
        date: this.date,
        groupid: this.gid,
      };
      console.log(data);

      timetable.presentPeriod(data).then((rtn) => {
        this.$store.commit("updateCurrentRegGroup", rtn);
        this.checks = {};
      });
    },
    cancelModal: function () {
      this.okPressed = false;
      this.showModalFlag = false;
    },
  },
};
</script>

<style>
ul.mylist {
  border: 1px solid #333;
}

ul.mylist > div > li {
  padding: 8px 16px;
  border-bottom: 1px solid #333;
}

ul.mylist > div > li:last-child {
  padding: 8px 16px;
  border-bottom: none;
}
</style>
