<template>
  <div class="container has-background-white-ter">
    <div class="container">
      <div
        class="box"
        style="
          align-content: flex-start;
          flex-direction: column;
          justify-content: space-between;
          display: flex;
        "
      >
        <h4 v-if="group[0]?.SubjectName" class="title is-4 is-spaced">
          {{ group[0]?.SubjectName }} ({{ group[0]?.GroupName }} -
          {{ group.length }} students)
        </h4>
        <a
          v-if="groupDetails?.urlClassroom"
          :href="groupDetails.urlClassroom"
          target="_blank"
        >
          <Icon icon="mdi:google-classroom" class="is-3" />Google Classroom
        </a>

        <div class="tabs is-boxed is-medium is-toggle">
          <ul>
            <li v-if="inLink('daybooks') && daybookAlert">
              <a @click="ackdayBooks()"> Acknowledge </a>
            </li>
          </ul>
        </div>
      </div>

      <router-view v-if="loaded" />
      <ModalProgress
        v-else
        message="Getting the most recent information"
        title="Loading...."
      />
    </div>

    <modal ref="myModal" @okPressed="ackedSEND" />
    <StudentModal ref="myStudentModal" />
    <RegisterModalSingle ref="myRegisterModalSingle" />
    <RegisterModalGroup
      ref="myRegisterModalGroup"
      @changed-time-table="changedTimeTable"
    />
    <ReportCardModal ref="myReportCardModal" />

    <nav
      class="navbar is-fixed-bottom is-info"
      role="navigation"
      aria-label="group navigation"
    >
      <div class="navbar-brand" style="padding-left: 14px">
        <a class="navbar-item" @click="changeTo('cards')"
          >{{ group[0]?.SubjectName }}
        </a>

        <a
          role="button"
          class="navbar-burger"
          aria-label="menu"
          ref="groupBurger"
          aria-expanded="false"
          data-target="navbarBasicExample"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="navbarBasicExample" ref="groupMenu" class="navbar-menu">
        <div class="navbar-start is-justify-content-center is-flex-grow-1">
          <a
            v-if="allowRegistration"
            class="navbar-item"
            @click="registerGroup()"
            >Register</a
          >
          <div v-else style="color: #333333" class="navbar-item">Register</div>

          <a
            v-if="allowRegistration && !inLink('lunch')"
            class="navbar-item"
            @click="changeTo('lunch')"
            >Register / Lunch</a
          >
          <div
            v-else-if="allowRegistration"
            style="color: #333333"
            class="navbar-item"
          >
            Register / Lunch
          </div>

          <a
            v-if="!inLink('daybooks')"
            class="navbar-item"
            @click="changeTo('daybooks')"
          >
            <span style="padding-right: 3px">Day Books</span>
            <span v-if="daybookAlert" class="icon is-small is-pulled-right"
              ><i class="fas fa-exclamation-triangle" aria-hidden="true"></i
            ></span>
          </a>
          <div v-else class="navbar-item" style="color: #333333">
            <span style="padding-right: 3px">Day Books</span>
            <span v-if="daybookAlert" class="icon is-small is-pulled-right"
              ><i class="fas fa-exclamation-triangle" aria-hidden="true"></i
            ></span>
          </div>

          <a
            v-if="!inLink('attr')"
            class="navbar-item"
            @click="changeTo('attr')"
            >Overview</a
          >
          <div v-else class="navbar-item" style="color: #333333">
            <span>Overview</span>
          </div>
        </div>

        <div class="navbar-end"></div>
      </div>
    </nav>
  </div>
</template>

<script>
import Modal from "Components/Modal";
import { Student } from "Classes/student";

import functions from "helpers/functions.js";

import StudentModal from "Components/school/pupilmodal";
import RegisterModalSingle from "Components/school/registersinglemodal";
import RegisterModalGroup from "Components/school/registergroupmodal";
import ReportCardModal from "Components/school/pupilreportcardmodal.vue";

import ModalProgress from "Components/ModalProgress.vue";

//import RegisterWithLunch from "Views/School/Timetable/Register/WithLunch.vue";
//import GroupHeader from "Components/school/group/header.vue"

//const ls = require('local-storage')

export default {
  name: "schoolTimetableGroup",

  components: {
    Modal,
    StudentModal,
    RegisterModalSingle,
    RegisterModalGroup,
    ReportCardModal,
    //RegisterWithLunch,
    ModalProgress,
  },

  data: function () {
    return {
      group: [],
      groupDetails: {},

      loadedG: false,
      loadedP: false,
      groupid: null,
      periodID: null,
      regDetails: {},
      regMarks: {},
      gid: null,
      pid: null,
      date: null,
      period: null,

      showDayBookMode: "new",
      aaaa: new Date(),
      daybookAlert: false,
      daybooks: [],
      maxDb: 0,
      wibble: 0,
    };
  },
  computed: {
    session() {
      return "hello";
    },

    loaded() {
      return this.loadedG && this.loadedP;
    },

    daybookButtonStyle() {
      console.log({ mode: this.mode, dayBookAlert: this.dayBookAlert });
      return (
        "navbar-item " +
        (this.mode == "daybook" ? "is-active" : "") +
        " " +
        (this.dayBookAlert ? "is-warning" : "")
      );
    },

    cRoute() {
      console.log({ HHHHH: this.$route.name, x: this.$options.name });
      return this.$route.name == this.$options.name;
    },

    allowRegistration() {
      console.log("Hellloooooooooooooooooooooooooooo", this.regDetails);
      return Object.keys(this.regDetails).length > 0;
    },

    lastDayBookEntry: function () {
      console.log("getting lastdayboe");

      return JSON.parse(
        localStorage.getItem(
          this.$store.state.currentDomain + "_#_DayBook_Gr#_" + this.gid
        ) ?? "0"
      );
    },
  },
  methods: {
    changeTo(link) {
      if (this.inLink(link)) {
        return;
      }

      console.log({ gid: this.gid, pid: this.periodID, date: this.date });
      let path = "/school/timetable/group/" + this.gid;

      if (this.periodID) {
        path = path + "/" + this.periodID + "/" + this.date;
      }

      path = path + "/" + link;
      this.$router.push({ path, params: { group: this.group } });
      this.$refs.groupMenu.classList.remove("is-active");
    },

    inLink(y) {
      let x =
        this.$route.path.endsWith("/" + y) ||
        this.$route.path.endsWith("/" + y + "/");
      console.log({ inlink: y, status: x });
      return x;
    },

    ackedSEND: function (x) {
      let state = {
        student: x[1],
        type: x[2],
        key: x[3],
      };

      let that = this;

      this.$api
        .post("v2/staff/student/info/ack/", true, true, state)
        .then((w) => {
          //console.log(["abcedfggggg", x, w, that.groups])
          //console.log([x[0]].acked])

          let b = x[0] % 3;
          let a = (x[0] - b) / 3;

          if (!that.groups[a][b].acked) {
            that.groups[a][b].acked = {};
          }

          if (!that.groups[a][b].acked[x[2]]) {
            that.groups[a][b].acked[x[2]] = {};
          }

          if (!that.groups[a][b].acked[x[2]]) {
            that.groups[a][b].acked[x[2]] = {};
          }

          that.groups[a][b].acked[x[2]][x[3]] = w.timestamp;

          //console.log("rrrrrrrrrrrrrrrrr", [x, that.groups[a][b]])

          that.wibble += 1;
        });

      //console.log("asasasasas", [x, this.groups])
    },

    ackdayBooks: function () {
      let students = this.$store.state.currentGroup?.group?.students ?? [];

      let state = students.reduce((p, x) => {
        const high = [0];

        Object.keys(x.daybook ?? {}).forEach((y) => {
          high[0] = x.daybook[y].reduce((pp, cc) => {
            return Math.max(cc.DBIdent, pp);
          }, high[0]);
        });

        p[x.PupilID] = high[0];

        return p;
      }, {});

      this.$api
        .post("v2/staff/student/daybooks/ack/", true, true, state)
        .then(() => {
          this.daybookAlert = false;
          this.$store.commit("acknowledgeDayBooks");
          this.changeTo("cards");
        });
    },

    changedTimeTable: function (x) {
      console.log("IN CHANGE OF TIMETABLE");

      let ok = true;
      let y = {};
      let instance = this;
      Object.keys(x["data"]).forEach((element) => {
        if (x["data"][element].GroupID != instance.gid) {
          ok = false;
          return;
        }

        y[x["data"][element]["PupilID"]] = x["data"][element];
      });

      if (ok) {
        this.regDetails = y;
      }
    },

    showAlert: function (params) {
      console.log([
        params.index,
        params.field,
        params.altfield,
        params.altfield ?? params.field,
      ]);

      this.$refs.myModal.showModal(
        this.group[params.index].allData.SEND[params.altfield ?? params.field],
        this.group[params.index].PupilFirstName +
          " " +
          this.group[params.index].PupilLastName,
        [params.index, this.group[params.index].PupilID, "SEND", params.field]
      );
    },

    showReportCard: function (index) {
      console.log([index, this.group[index]]);
      let student = Object.assign(new Student(), this.group[index]);

      this.$refs.myReportCardModal.showModal(student);
    },

    changeRegistrationState: function (index, currentrm, regPeriod) {
      let student = Object.assign(new Student(), this.group[index]);

      this.$refs.myRegisterModalSingle.showModal(
        student,
        currentrm,
        regPeriod,
        this.regMarks
      );
    },

    registerGroup: function () {
      this.$refs.myRegisterModalGroup.showModal(
        this.group,
        this.regDetails,
        this.regMarks,
        this.date,
        this.period,
        this.periodID
      );
    },
  },

  watch: {
    "$route.params.gid": {
      handler(value) {
        console.log(value, "ROUTE GID CHANGED");
        this.daybookAlert = false;
        let parts = functions.groupID(value);

        this.loadedG = false;
        this.loadedP = false;
        this.regDetails = {};
        if (this.group.length == 0 || this.gid !== parts[0]) {
          this.$store.commit("setCurrentGroup", null);
          this.gid = parts[0];
          this.$api
            .get("v2/staff/group/" + parts[0] + "/", true, true)
            .then((x) => {
              this.group = x.group.students;
              this.daybookAlerts = x.daybookAlerts;
              this.daybookAlert = x.daybookAlert ?? false;

              this.groupDetails = x.group.details;
              this.$store.commit("setCurrentGroup", x);
              this.loadedG = true;
            });
        }

        if (parts.length === 1) {
          this.loadedG = true;
          this.loadedP = true;
          return;
        }

        let lDate = parts[2] ?? new Date().toISOString().substring(0, 10);

        if (this.periodID === parts[1] && lDate === this.date) {
          this.loadedP = true;
          return;
        }

        this.periodID = parts[1];
        this.date = lDate;

        console.log(
          "v2/staff/group/" + this.gid + "/" + lDate + "/" + this.periodID + "/"
        );
        this.$api
          .get(
            "v2/staff/group/" +
              this.gid +
              "/" +
              lDate +
              "/" +
              this.periodID +
              "/",
            true,
            true
          )
          .then((x) => {
            let regDetails = {};

            Object.keys(x["data"]).forEach((element) => {
              regDetails[x["data"][element]["PupilID"]] = x["data"][element];
            });

            this.regDetails = regDetails;

            console.log([y, "hello"]);

            let y = {};

            this.period = x.period;

            Object.keys(x?.["options"] ?? {}).forEach((element) => {
              y[x["options"][element]["ident"]] = x["options"][element];
            });

            y[0] = { ident: 0, code: "?" };

            this.regMarks = y;

            this.$store.commit("setCurrentRegGroup", {
              students: regDetails,
              marks: y,
            });

            console.log([y, "hello2"]);
            this.loadedP = true;
          });
      },
      immediate: true,
    },
  },

  created() {},
  mounted() {
    const menu = document.getElementById("navbarBasicExample");
    const w = this.$refs.groupBurger;
    this.$refs.groupBurger.addEventListener("click", function () {
      w.classList.toggle("is-active");
      menu.classList.toggle("is-active");
    });
  },
};
</script>

<style>
www {
  box-shadow: 0 0 0 0 rgba(#5a99d4, 0.5);
  -webkit-animation: pulse 1.5s infinite;
}

@-webkit-keyframes pulse {
  0% {
    @include transform(scale(0.9));
  }

  70% {
    @include transform(scale(1));
    box-shadow: 0 0 0 50px rgba(#5a99d4, 0);
  }

  100% {
    @include transform(scale(0.9));
    box-shadow: 0 0 0 0 rgba(#5a99d4, 0);
  }
}
</style>
