<template>
  <div class="modal" :class="showModalFlag ? 'is-active' : ''">
    <div class="modal-background"></div>
    <div v-if="showModalFlag" class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title" v-if="student">{{ student.name() }}</p>
        <p class="modal-card-title" v-else>Loading.....</p>
        <button class="delete" aria-label="close" v-on:click="cancelModal()">></button>
      </header>
      <section class="modal-card-body" v-if="student">
        <article class="media tile is-child box">
          <ReportCard :locked="true"  :student="student" />
        </article>


      </section>


      <footer class="modal-card-foot">
        <button class="button is-success" v-on:click="okModal()">Ok</button>
        <button class="button" v-on:click="cancelModal()">Cancel</button>
      </footer>
    </div>
  </div>
</template>


<script>

import { getStudent } from '../../classes/student';
import ReportCard from 'Components/school/student/reportcard.vue'

export default {
  name: "modal-view-student",

  components: {
    ReportCard
  },


  data: function () {
    return {
      showModalFlag: false,
      okPressed: false,
      student: null,
      loading: false,
      tabActive: 'contacts',
      attributes: {}
    };
  },

  computed: {
    runMode: function () { return this.$getConst('runMode') },

  },
  methods: {
    showModal: async function (student) {

      this.okPressed = false;
      this.showModalFlag = true;
      this.tabActive = 'contacts'

      if (typeof (student) === "object") {
        this.student = student
          return;
        }

      this.loading = true
      this.student = null
      this.student = await getStudent(student)
      this.loading = false



    },
    okModal: function () {
      this.okPressed = true;
      this.showModalFlag = false;
    },
    cancelModal: function () {
      this.okPressed = false;
      this.showModalFlag = false;
    },
    missingStudent() {
      const x = this.$store.getters.currentUserDetails;

      const name = x.Title + " " + x.FirstName + " " + x.LastName

      const message = this.student.PupilFirstName + " " + this.student.PupilLastName + "\n\nShould be in my lesson but is not here"
      this.$store.state.channel.publish('mainroom', {
        name,
        message,
        override: true
      });
    }
  }
}
</script>