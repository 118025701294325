<template>
    <div class="modal is-active">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ title }}</p>
        </header>
        <section class="modal-card-body">
            <progress class="progress is-large is-primary" max="100">{{ progress }}%</progress>
        </section>

        <footer v-if="message" class="modal-card-foot">{{ message }}</footer>
      </div>
    </div>
</template>


<script>


export default {
  name: "modal-progress",

  props: {
    progress: {
      type: Number,
      default: 60
    },
    message: {
      type: String,
      required: false
    },
    title: {
      type: String,
      required: true
    }

  }

}
</script>