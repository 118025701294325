<template>
  <div class="is-centered">
    <progress
      v-if="!loaded"
      class="progress is-large is-primary"
      max="100"
    ></progress>

    <form v-else class="form" @submit.prevent="submitReportCard()">
      <div
        v-if="locked"
        style="
          display: flex;
          justify-content: space-between;
          padding-bottom: 1em;
        "
      >
        <strong
          >{{ metadata.Title }} {{ metadata.FirstName }}
          {{ metadata.LastName }}</strong
        >
        <strong>{{ metadata.timestamp }}</strong>
      </div>
      <div class="field">
        <label class="label"
          >Why is {{ student.firstName() }} {{ locked ? "" : "being put" }} on
          report?</label
        >
        <div class="control">
          <input
            class="input"
            :disabled="locked"
            type="text"
            placeholder="Reason for being on report"
            v-model="reason"
            required
          />
        </div>
      </div>

      <div class="field">
        <label class="label">Target One</label>
        <div class="control">
          <input
            :disabled="locked"
            class="input"
            type="text"
            v-model="target1"
            required
          />
        </div>
      </div>

      <div class="field">
        <label class="label">Target Two</label>
        <div class="control">
          <input
            :disabled="locked"
            class="input"
            type="text"
            v-model="target2"
            required
          />
        </div>
      </div>

      <div class="field">
        <label class="label">Target Three</label>
        <div class="control">
          <input
            :disabled="locked"
            class="input"
            type="text"
            v-model="target3"
            required
          />
        </div>
      </div>

      <div class="field">
        <label class="label">Starts</label>
        <div class="control">
          <input
            :disabled="locked"
            class="input"
            type="date"
            v-model="starts"
            required
          />
        </div>
      </div>

      <div class="field">
        <label class="label">Ends</label>
        <div class="control">
          <input
            :disabled="locked"
            class="input"
            type="date"
            v-model="ends"
            required
          />
        </div>
      </div>

      <div
        v-if="!locked"
        class="field is-grouped"
        style="justify-content: center"
      >
        <p class="control">
          <button role="submit" class="button is-primary">Submit</button>
        </p>
        <p class="control">
          <button class="button is-danger" @click="cancelReportCard()">
            Remove
          </button>
        </p>
      </div>
    </form>
  </div>
</template>

<script>
//import { Icon } from "@iconify/vue2";

import format from "date-fns/format";
import parseISO from "date-fns/parseISO";

export default {
  name: "reportCard",

  props: ["student", "locked"],

  data() {
    return {
      reason: null,
      target1: null,
      target2: null,
      target3: null,
      starts: null,
      ends: null,
      loaded: false,
      metadata: null,
    };
  },

  //components: { Icon, },

  mounted() {
    if (!this.student.getAlerts()?.reportcard) {
      this.loaded = true;
      return;
    }

    this.$api
      .get(
        "v2/staff/student/" + this.student.PupilID + "/alert/reportcard",
        true,
        true
      )
      .then((r) => {
        console.log({ r, x: "sjsdsds" });
        this.reason = r.value.message.reason;
        this.target1 = r.value.message.target1;
        this.target2 = r.value.message.target2;
        this.target3 = r.value.message.target3;
        this.starts = r.value.message.starts;
        this.ends = r.value.message.ends;

        this.metadata = r.metadata;

        console.log(
          this.metadata.timestamp,
          parseISO(r.metadata.timestamp) + 10000
        );

        this.metadata.timestamp = format(
          parseISO(r.metadata.timestamp),
          "do LLL yyyy"
        );

        this.loaded = true;
      });
  },

  methods: {
    showAlert(index, field, altfield = null) {
      this.$emit("showAlert", { index, field, altfield });
    },
    showStudentModal(index) {
      this.$emit("showStudentModal", index);
    },

    submitReportCard() {
      const data = {
        reason: this.reason,
        target1: this.target1,
        target2: this.target2,
        target3: this.target3,
        starts: this.starts,
        ends: this.ends,
      };

      this.$api
        .post(
          "v2/staff/student/" + this.student.PupilID + "/alert/reportcard",
          true,
          true,
          data
        )
        .then((r) => {
          console.log(r);
        });
    },
  },
};
</script>
